import axios from "axios";

axios.defaults.withCredentials = false;
const AxiosWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://waitlistapi.mobiginie.com/api/v1/",
  // baseURL: "http://locahost:4304/api/v1/",
  crossDomain: false,
});


export default AxiosWithoutToken;



