import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Box,
  Paper,
  Divider,
  Autocomplete,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { BackButton, Button, Heading, Input } from "../../components/Common";
import { serviceDropdown } from "../../Service/ServiceDepartment.service";
import { useFormik } from "formik";
import { createSericeByUserInitialValues } from "../../helper/initialValues";
import { createServiceRequestValidationSchema } from "../../helper/validation";
import { getAllStates, getCity } from "../../Service/location.service";
import Notification from "../../components/Common/Notification/Notification"
import dayjs from 'dayjs';
import { waitlistAdd, waitlistDetail, updateWaitlist } from "../../Service/Waitlist.service";
import { useLocation, useNavigate } from 'react-router-dom';
import { getAgencybasedOnService } from "../../Service/Agency.service";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default function CreateService() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log('state', state);
  // Working on this file
  const [selectedService, setSelectedService] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [serviceData, setServiceData] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [availability, setAvailability] = useState({
    monday: { available: false, fromTime: null, toTime: null, day: 'monday' },
    tuesday: { available: false, fromTime: null, toTime: null, day: 'tuesday' },
    wednesday: { available: false, fromTime: null, toTime: null, day: 'wednesday' },
    thursday: { available: false, fromTime: null, toTime: null, day: 'thursday' },
    friday: { available: false, fromTime: null, toTime: null, day: 'friday' },
    saturday: { available: false, fromTime: null, toTime: null, day: 'saturday' },
    sunday: { available: false, fromTime: null, toTime: null, day: 'sunday' },
  });
  const [removedSlotes, setRemovedSlotes] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectedSlotes, setSelectedSlotes] = useState([]);
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  console.log('selectedSlotes -=-=-==-=', selectedSlotes);
  console.log('availability -=-=-==-=', availability);

  const handleCloseConfirmationModel = () => {
    setConfirmationModel(false);
  }


  const formik = useFormik({
    initialValues: createSericeByUserInitialValues,
    onSubmit: async (values) => {
      if (availability.length === 0) {
        setShowNotification({
          show: true,
          type: 'error',
          message: 'Please select availability.',
        });
        return;
      }
      const payload = {
        serviceId: values.serviceId,
        agencyId: values.agencyId,
        initialDate: values.initialDate,
        childName: values.childName,
        childDOB: values.childDOB,
        address1: values.address1,
        address2: values.address2,
        state: values.stateId,
        city: values.cityId,
        zipcode: values.zipcode,
        insurance: values.insurance == 'Yes' ? 1 : 2,
        insuranceName: values.insuranceName,
        parentName: values.parentName,
        parentPhone: values.parentPhone,
        parentEmail: values.parentEmail,
        waitlistAvailability: selectedSlotes,
        deleteAvailability: removedSlotes,
      };
      const response = isEdit ? await updateWaitlist(state?.id, payload) : await waitlistAdd(payload);
      if (response.status) {
        setShowNotification({
          show: true,
          type: 'success',
          message: response.message,
        });
        formik.resetForm();
        navigate('/waitlist-status');
      } else {
        setShowNotification({
          show: true,
          type: 'error',
          message: response.message,
        });
      }
    },
    validationSchema: createServiceRequestValidationSchema,
  });

  console.log('formik', formik.errors);
  console.log('formik', formik.values);

  const handleAvailabilityChange = (event) => {
    const { name, value, type, checked, day } = event.target;
    setAvailability((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        ...(type === "checkbox"
          ? { available: checked }
          : { [type]: value }),
      },
    }));
    // set blank value whrn checkbox is unchecked on availability

    // if checkbox is unchecked then remove the day from selectedSlotes
    if (type === "checkbox" && !checked) {
      const selectedSlot = selectedSlotes.find((slot) => slot.day === name);
      if (selectedSlot) {
        setRemovedSlotes([...removedSlotes, selectedSlot]);
      }
      const updatedDataAfterRemove = selectedSlotes.filter((slot) => slot.day !== name);
      console.log('updatedDataAfterRemove', updatedDataAfterRemove);
      const FinalSlotData = [...updatedDataAfterRemove];
      setSelectedSlotes(FinalSlotData);
    }
  };

  const handleOnChnageService = async (id) => {
    const response = await getAgencybasedOnService(id);
    if (response.status) {
      setAgencyList(response.data);
    } else {
      setAgencyList([]);
      setShowNotification({
        show: true,
        type: 'error',
        message: response.message,
      });
    }
  }

  const handelUpdateMainSlot = (day) => {

    // check if day is already in selectedSlotes then update the time

    const isDayExist = selectedSlotes.find((slot) => slot.day === day);
    if (isDayExist) {
      const NewupdatedData = selectedSlotes.map((slot) => {
        if (slot.day === day) {
          slot.fromTime = dayjs(availability[day]?.fromTime?.$d).format('HH:mm');
          slot.toTime = dayjs(availability[day]?.toTime?.$d).format('HH:mm');
        }
        return slot;
      });
      const neItems = [...NewupdatedData];
      setSelectedSlotes(neItems);
    } else {
      const SlotData = {
        day: day,
        fromTime: dayjs(availability[day]?.fromTime?.$d).format('HH:mm'),
        toTime: dayjs(availability[day]?.toTime?.$d).format('HH:mm')
      }
      const updatedData = [...selectedSlotes, SlotData];
      setSelectedSlotes(updatedData);
    }
  }

  const getServiceData = async () => {
    const response = await serviceDropdown();
    if (response?.status) {
      setServiceData(response?.data);
    } else {
      setServiceData([]);
    }
  };

  const getAllStatesData = async () => {
    try {
      const res = await getAllStates();
      if (res.status) {
        setAllStates(res.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleCityDataByStateId = async (stateData) => {
    if (stateData) {
      formik.setFieldValue('city', '');
      const CitiesData = await getCity(stateData && stateData?.id ? stateData.id : stateData);
      if (CitiesData.status) {
        setAllCities(CitiesData.data);
      }
    }
  }

  const handleInsuranceChange = (event) => {
    setSelectedInsurance(event.target.value);
    formik.setFieldValue("insurance", event.target.value);
  }

  const getWaitlistDetails = async (id) => {
    try {
      const response = await waitlistDetail(id);
      if (response.status) {
        const data = response.data;
        formik.setFieldValue('serviceId', data.serviceId?.id);
        formik.setFieldValue('agencyId', data.agencyId.id);
        formik.setFieldValue('initialDate', data.initialDate);
        formik.setFieldValue('childName', data.childName);
        formik.setFieldValue('childDOB', data.childDOB);
        formik.setFieldValue('address1', data.address1);
        formik.setFieldValue('address2', data.address2);
        formik.setFieldValue('stateId', data.state?.stateID);
        formik.setFieldValue('cityId', data.city?.cityID)
        formik.setFieldValue('zipcode', data.zipcode);
        formik.setFieldValue('insurance', data.insurance === 1 ? 'Yes' : 'No');
        formik.setFieldValue('insuranceName', data.insuranceName);
        formik.setFieldValue('parentName', data.parentName);
        formik.setFieldValue('parentPhone', data.parentPhone);
        formik.setFieldValue('parentEmail', data.parentEmail);
        setSelectedInsurance(data.insurance === 1 ? 'Yes' : 'No');
        setInsuranceName(data.insuranceName);
        setStartDate(data.initialDate);

        const updatedAvailability = { ...availability };
        data.waitlistAvailability.forEach((slot) => {
          const { day, fromTime, toTime, status } = slot;
          updatedAvailability[day] = {
            available: status === 1,
            fromTime: fromTime ? dayjs(`1970-01-01T${fromTime}`) : null,
            toTime: toTime ? dayjs(`1970-01-01T${toTime}`) : null,
            day,
          };
        });
        console.log('updatedAvailability', updatedAvailability);

        setAvailability(updatedAvailability);
        setSelectedSlotes(data.waitlistAvailability);
        handleOnChnageService(data.serviceId.id);
        handleCityDataByStateId(data.state?.stateID);
      }
    }
    catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getServiceData();
    getAllStatesData();
  }, []);

  useEffect(() => {
    if (state && state?.id) {
      getWaitlistDetails(state.id);
      setIsEdit(true);
    }
  }, [state]);



  return (
    <>
      <Stack gap={4}>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <BackButton />
          <Heading head={"Service Request"} />
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Paper elevation={4} sx={{ padding: 4, borderRadius: 3 }}>
            <Grid container spacing={2}>
              {/* Select Service Dropdown */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography variant="h6">Select Service</Typography>
                  <Autocomplete
                    value={
                      serviceData.find(
                        (data) => data.id === formik.values.serviceId
                      ) || null
                    }
                    name="serviceId"
                    onChange={(event, newValue) => {
                      formik.setFieldValue('serviceId', newValue ? newValue.id : "");
                      handleOnChnageService(newValue.id);
                    }}
                    options={serviceData}
                    getOptionLabel={(option) => option.serviceName || ""}
                    renderInput={(params) => (
                      <Input {...params} variant="outlined"
                        placeholder="Select Service"
                        error={formik.touched.serviceId && Boolean(formik.errors.serviceId)}
                        helperText={formik.touched.serviceId && formik.errors.serviceId}
                      />
                    )}
                  />
                </FormControl>
              </Grid>


              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography variant="h6">Select Agency</Typography>
                  <Autocomplete
                    value={
                      agencyList.find(
                        (data) => data.id === formik.values.agencyId
                      ) || null
                    }
                    name="agencyId"
                    onChange={(event, newValue) => {
                      formik.setFieldValue('agencyId', newValue ? newValue.id : "");
                    }}
                    options={agencyList}
                    getOptionLabel={(option) => option.agencyName || ""}
                    renderInput={(params) => (
                      <Input {...params} variant="outlined"
                        placeholder="Select Service"
                        error={formik.touched.agencyId && Boolean(formik.errors.agencyId)}
                        helperText={formik.touched.agencyId && formik.errors.agencyId}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Date Init and Wait Time */}
              <Grid item xs={12} sm={3} sx={{ mt: { xs: 0, sm: 0 } }}>
                <Input
                  type="date"
                  labelinput={"Date Initiated"}
                  placeholder="Date Initiated"
                  formik={formik}
                  name="initialDate"
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
              <Input
                labelinput={"Est. Wait Time"}
                placeholder="Est. Wait Time"
              />
            </Grid> */}

              {/* Child's Info */}
              <Grid item xs={6} sm={6}>
                <Input
                  labelinput={"Child's Name"}
                  placeholder="Child's Name"
                  formik={formik}
                  name="childName"
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Input
                  type="date"
                  labelinput={"Child's DOB"}
                  placeholder="Child's DOB"
                  formik={formik}
                  name="childDOB"
                />
              </Grid>

              {/* Address Information */}
              <Grid item xs={12}>
                <Divider
                  style={{ marginTop: "16px", marginBottom: "16px", fontWeight: 700, fontSize: "19px" }}
                >Address</Divider>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  labelinput={"Address Line 1"}
                  placeholder={"Address Line 1"}
                  name="address1"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  labelinput={"Address Line 2"}
                  name="address2"
                  formik={formik}
                  placeholder={"Address Line 2"}
                />
              </Grid>

              <Grid item xs={4} sm={4}>
                <Typography variant="h6">Select State</Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    value={allStates.filter(state => state.id == formik.values.stateId).length > 0 ? allStates.filter(state => state.id == formik.values.stateId)[0] : null}
                    onChange={(event, newValue) => {
                      if (newValue == null) {
                        formik.setFieldValue('city', '');
                      }
                      formik.setFieldValue('state', newValue);
                      formik.setFieldValue('stateId', newValue?.id);

                      handleCityDataByStateId(newValue)
                    }}
                    options={allStates}
                    getOptionLabel={(option) => option.stateName || ""}
                    renderInput={(params) => (
                      <Input {...params} variant="outlined"
                        error={formik.touched.stateId && Boolean(formik.errors.stateId)}
                        helperText={formik.touched.stateId && formik.errors.stateId}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4}>
                <FormControl sx={{ width: { xs: "100%", sm: "100%" } }}>
                  <Typography fontSize={{ xs: '14px', sm: '14px' }} fontWeight={500}>Select City</Typography>
                  <Autocomplete
                    value={allCities.filter(city => city.cityID == formik.values.cityId).length > 0 ? allCities.filter(city => city.cityID == formik.values.cityId)[0] : null}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('city', newValue);
                      formik.setFieldValue('cityId', newValue?.cityID);
                    }}
                    options={allCities}
                    getOptionLabel={(option) => option.cityName || ""}
                    renderInput={(params) => (
                      <Input {...params} variant="outlined"
                        error={formik.touched.cityId && Boolean(formik.errors.cityId)}
                        helperText={formik.touched.cityId && formik.errors.cityId}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Input
                  labelinput={"ZipCode"}
                  name="zipcode"
                  formik={formik}
                  placeholder={"ZipCode"}
                />
              </Grid>

              {/* Insurance Info */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Typography variant="h6">Insurance</Typography>
                  <Select
                    value={selectedInsurance}
                    onChange={handleInsuranceChange}
                    fullWidth
                    error={formik.touched.insurance && Boolean(formik.errors.insurance)}
                    helperText={formik.touched.insurance && formik.errors.insurance}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {selectedInsurance === "Yes" && (
                <Grid item xs={6}>
                  <Input
                    labelinput={"Insurance Name"}
                    placeholder="Insurance Name"
                    formik={formik}
                    name="insuranceName"
                  />
                </Grid>
              )}

              {/* Parent's Info */}
              <Grid item xs={6}>
                <Input labelinput={"Parent's Name"} placeholder="Parent's Name" formik={formik} name="parentName" />
              </Grid>
              <Grid item xs={6}>
                <Input
                  labelinput={"Parent's Phone"}
                  placeholder="Parent's Phone"
                  formik={formik}
                  name="parentPhone"
                />
              </Grid>
              <Grid item xs={6}>
                <Input labelinput={"Parent Email"} placeholder="Parent Email" formik={formik} name="parentEmail" />
              </Grid>

              {/* Availability */}
              <Grid item xs={12}>
                <Divider style={{ marginTop: "16px", marginBottom: "16px", fontWeight: 700, fontSize: "19px" }}>Availability</Divider>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormGroup>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <span style={{ fontWeight: 700 }}>From</span>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <span style={{ fontWeight: 700 }}>To</span>
                    </Grid>
                  </Grid>
                  {Object.keys(availability).map((day) => (
                    <Grid container spacing={1} key={day} alignItems="center">
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={availability[day].available}
                              onChange={handleAvailabilityChange}
                              name={day}
                            />
                          }
                          label={day.charAt(0).toUpperCase() + day.slice(1)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {/* <DemoContainer components={['TimePicker', 'TimePicker']}> */}
                        <TimePicker
                          // ampm={false}
                          value={availability[day]?.fromTime}
                          onChange={(newValue) => {
                            handleAvailabilityChange({
                              target: {
                                name: day,
                                value: newValue,
                                type: "fromTime",
                                day: day
                              },
                            })
                            handelUpdateMainSlot(day)
                          }
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                          disabled={!availability[day]?.available}
                        />
                        {/* </DemoContainer> */}
                      </Grid>
                      <Grid item xs={4}>
                        <TimePicker
                          // ampm={false}
                          value={availability[day]?.toTime}
                          onChange={(newValue) => {
                            handleAvailabilityChange({
                              target: { name: day, value: newValue, type: "toTime", day: day },
                            });
                            handelUpdateMainSlot(day)
                          }
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                          disabled={!availability[day].available}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </FormGroup>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} mt={1}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* {isEdit ? (
                    <Button buttonName={"Edit"} color="secondary" />) : ""
                  } */}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Button buttonName={"Cancel"} color="tertiary" onClick={formik.resetForm} />
                    <Button buttonName={"Save"} color="primary" onClick={() => setConfirmationModel(true)} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </LocalizationProvider>
      </Stack>


      <Dialog
        // onClose={handleClose}
        aria-labelledby="Confirmation_Model"
        open={confirmationModel}
      // className={modalOpen.class}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="Confirmation_Model">
          Attention!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseConfirmationModel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
        <DialogContent>
          <Box width={{ sm: '400px' }}>
            <Typography fontSize={'18px'}>
              By Clicking Ok, I provide consent for my information to be shared with other providers for the purpose of accessing services requested
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 24px'
        }}>
          <Button buttonName={'Ok'} size='small' color='primary' onClick={formik.handleSubmit} />
          <Button buttonName={'Cancel'} size='small' color='white' onClick={handleCloseConfirmationModel} />
        </DialogActions>
      </Dialog>
      <Notification show={showNotification} setShow={setShowNotification} />
    </>
  );
}
