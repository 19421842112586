import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { waitlistDetail } from "../../Service/Waitlist.service";
import moment from "moment";
import { convertTo12HourFormat } from "../../helper/TimeConvter";


export default function WailistDetailsPopup({ id }) {

    const [detailsData, setDetailsData] = useState({});
    console.log('detailsData', detailsData);

    const handleGetDetailsData = async () => {
        try {
            const response = await waitlistDetail(id);
            if (response.status) {
                setDetailsData(response.data);
            } else {
                console.log('error', response);
                setDetailsData({});
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    useEffect(() => {
        handleGetDetailsData();
    }, []);

    return (
        <>
            <Grid container spacing={2} maxWidth={{ sm: "600px" }}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Service Type:</Typography>
                    <Typography variant="body2">{detailsData?.serviceId?.serviceName || '-'} </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Agency:</Typography>
                    <Typography variant="body2">{detailsData?.agencyId?.agencyName || '-'}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Position:</Typography>
                    <Typography variant="body2">{detailsData?.position || 0}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Child Name:</Typography>
                    <Typography variant="body2">{detailsData?.childName || "-"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Child DOB:</Typography>
                    <Typography variant="body2">{detailsData && detailsData?.childDOB ? moment(detailsData?.childDOB).format('YYYY-MM-DD') : "-"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Parents Name:</Typography>
                    <Typography variant="body2">{detailsData?.parentName || "-"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Parents Email:</Typography>
                    <Typography variant="body2">{detailsData?.parentEmail || "-"}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} mt={2}>
                    <Typography variant="subtitle1"><strong>Insurance Details:</strong></Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Insurance Available?:</Typography>
                    <Typography variant="body2">{detailsData?.insurance ? "Yes" : "No"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1">Insurance Name:</Typography>
                    <Typography variant="body2">{detailsData?.insuranceName || "-"}</Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} mt={2}>
                    <Typography variant="subtitle1"><strong>Time Slot:</strong></Typography>
                </Grid>
                {detailsData && detailsData.waitlistAvailability && detailsData.waitlistAvailability.length > 0 ?
                    detailsData.waitlistAvailability.map((item) => (
                        <Grid xs={6} sm={3}>
                            <Typography variant="body1" mt={1}>
                                <strong>Day:</strong> {item?.day || '-'}
                            </Typography>
                            <Typography variant="body1">
                                <strong>From:</strong> {convertTo12HourFormat(item?.fromTime) || "-"}
                            </Typography>
                            <Typography variant="body1">
                                <strong>To:</strong> {convertTo12HourFormat(item?.toTime) || "-"}
                            </Typography>
                        </Grid>
                    )) : "No Data Found"
                }


            </Grid>
        </>
    )
}