import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "../../Common";
import { serviceDropdown } from "../../../Service/ServiceDepartment.service";
import { getAgencybasedOnService } from "../../../Service/Agency.service";
import { refterToOthergancy } from "../../../helper/validation";
import { transfrerToOtherAgency } from "../../../Service/Referral.service";
import { useFormik } from "formik";
import Notification from "../../../components/Common/Notification/Notification"

const Reffer_modal = ({
  handleClose,
  modalOpen,
  handelClick,
  handleChange,
  status,
  setRefresh, refresh
}) => {

  const [serviceList, setServiceList] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });

  const formik = useFormik({
    initialValues: {
      serviceType: "",
      agencyId: "",
      note: "",
      waitlistId: "",
    },
    onSubmit: async (values) => {
      const payload = {
        serviceId: values.serviceType,
        agencyId: values.agencyId,
        note: values.note,
        waitlistId: values.waitlistId,
      };
      console.log(payload);
      const saveRes = await transfrerToOtherAgency(payload);
      if (saveRes.status) {
        setShowNotification({
          show: true,
          type: 'success',
          message: saveRes.message
        });
        handleClose();
        setRefresh(!refresh);
      } else {
        setShowNotification({
          show: true,
          type: 'error',
          message: saveRes.message
        });
      }
    },
    validationSchema: refterToOthergancy,
  });


  const getServiceDropdownData = async () => {
    try {
      const res = await serviceDropdown();
      if (res.status) {
        setServiceList(res.data);
      } else {
        setServiceList([]);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleOnChnageService = async (id) => {
    const response = await getAgencybasedOnService(id);
    if (response.status) {
      setAgencyList(response.data);
    } else {
      setAgencyList([]);
    }
  }

  useEffect(() => {
    getServiceDropdownData();
  }, []);

  useEffect(() => {
    if (modalOpen.para) {
      formik.setValues({
        serviceType: modalOpen.para.serviceid,
        waitlistId: modalOpen.para.id
      });
      handleOnChnageService(modalOpen.para.serviceid);
    }
  }, [modalOpen.para]);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Select Refer Agency
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ maxWidth: { sm: "500px" } }}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Service"}
            </Typography>

            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={
                  serviceList.find(
                    (data) => data.id === formik.values.serviceType
                  ) || null
                }
                name="serviceType"
                onChange={(event, newValue) => {
                  formik.setFieldValue('serviceType', newValue ? newValue.id : "");
                }}
                options={serviceList}
                getOptionLabel={(option) => option.serviceName || ""}
                renderInput={(params) => <TextField {...params}
                  placeholder="Select Service"
                  error={formik.touched.serviceType && Boolean(formik.errors.serviceType)}
                  helperText={formik.touched.serviceType && formik.errors.serviceType}
                />}
                readOnly={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6">Select Agency</Typography>
            <Autocomplete
              value={
                agencyList.find(
                  (data) => data.id === formik.values.agencyId
                ) || null
              }
              name="agencyId"
              onChange={(event, newValue) => {
                formik.setFieldValue('agencyId', newValue ? newValue.id : "");
              }}
              options={agencyList}
              getOptionLabel={(option) => option.agencyName || ""}
              renderInput={(params) => (
                <Input {...params}
                  placeholder="Select Agency"
                  error={formik.touched.agencyId && Boolean(formik.errors.agencyId)}
                  helperText={formik.touched.agencyId && formik.errors.agencyId}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Note"}
              type="text"
              multiline
              rows={3}
              placeholder={"Enter Note..."}
              formik={formik}
              name="note"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancle"
          size="small"
          color="tertiary"
          onClick={handleClose}
        />
        <Button
          buttonName="Add"
          size="small"
          color="primary"
          onClick={formik.handleSubmit}
        />
      </DialogActions>

      <Notification show={showNotification} setShow={setShowNotification} />
    </>
  );
};

export default Reffer_modal;
