export const loginInitialValues = {
  email: "",
  password: "",
  rememberMe: false,
};

export const createSericeByUserInitialValues = {
  serviceId: '',
  agencyId: '',
  initialDate: '',
  // waitTime: '',
  childName: '',
  childDOB: '',
  address1: '',
  address2: '',
  stateId: '',
  state: '',
  city: '',
  cityId: '',
  zipcode: '',
  parentName: '',
  parentPhone: '',
  parentEmail: '',
  insurance: '',
  insuranceName: '',
  waitlistAvailability: [],
};

export const addAdminInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  mobile: '',
  image: '',
  profile: '',
  companyId: ""
};

export const changePasswordInitialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const addServiceInitialValues = {
  serviceName: '',
  id: '',
  isEdit: false
};

export const profileInitialValues = {
  firstName: '',
  lastName: '',
  image: '',
};

export const agencyAddInitialValues = {
  name: '',
  email: '',
  mobile: '',
  stateId: '',
  cityId: '',
  contactPerson: '',
  contactPhone: '',
  address1: '',
  address2: '',
  zipCode: '',
  state: '',
  city: '',
  services: [],
  isEdit: false,
  servideIds: []
};

export const agencyProfileInitialValues = {
  name: '',
  email: '',
  mobile: '',
  stateId: '',
  cityId: '',
  contactPerson: '',
  contactPhone: '',
  address1: '',
  address2: '',
  zipCode: '',
  state: '',
  city: '',
  // services: [],
  isEdit: false,
  // servideIds: []
};


export const agencyFilterInitialValues = {
  status: '',
  isRegistered: '',
};

export const planAddInitialValues = {
  planName: '',
  planDurationType: '',
  expirationDuration: '',
  planPrice: '',
  bonusDays: 0,
  perMonthPrice: '',
  planDescription: '',
  planDescriptionId: '',
  isEdit: false
};


export const userilterInitialValues = {
  status: '',
};

export const userInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  address1: '',
  address2: '',
  zipCode: '',
  state: '',
  city: '',
  stateId: '',
  cityId: '',
  id: '',
};

export const userRegisterInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  address1: '',
  address2: '',
  zipCode: '',
  state: '',
  city: '',
  password: '',
};

export const agencyRegisterInitialValues = {
  agencyName: '',
  email: '',
  mobile: '',
  address1: '',
  address2: '',
  zipCode: '',
  password: '',
  state: '',
  city: '',
  password: '',
  servideIds: []
};
