import React, { useState, useEffect } from "react";
import { Button, Heading, Input, Modal } from "../../components/Common";
import { VscFilter } from "react-icons/vsc";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Collapse,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Switch,
  Autocomplete,
  Popover,
  Tooltip,
} from "@mui/material";
import { MdDelete, MdSearch, MdVisibility } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { GrTransaction } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { waitlistListAgency, waitlistExport } from "../../Service/Waitlist.service";
import { transfrerToOtherAgency } from "../../Service/Referral.service";
import { serviceDropdown } from "../../Service/ServiceDepartment.service";
import { useFormik } from "formik";
import CommonSelect from "../../components/Common/Select";

const WaitingList = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [waitlistData, setWaitlistData] = useState([]);
  const [agencyDropdown, setAgencyDropdown] = useState([]);
  const [services, setServices] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      status: "",
    },
    onSubmit: (values) => {
      console.log(values);
      handleWaitlistData();
    },
  });

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const getServiceDropdownData = async () => {
    try {
      const res = await serviceDropdown();
      if (res.status) {
        setAgencyDropdown(res.data);
      } else {
        setAgencyDropdown([]);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleWaitlistData = async () => {
    try {
      let qry = `?search=${search}&serviceId=${services}&name=${formik.values.name}&status=${formik.values.status}`;
      const response = await waitlistListAgency(qry);
      if (response.status) {
        setWaitlistData(response.data);
        setRefresh(false);
      } else {
        console.log(response.message);
        setWaitlistData([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getServiceDropdownData();
  }, []);

  useEffect(() => {
    handleWaitlistData();
  }, [search, services, refresh]);


  return (
    <>
      <Stack spacing={2}>
        <Heading head={"Waitlist Management"} />
        <Stack
          mb={2}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexShrink={1}
          flexWrap={"wrap"}
          gap={2}
        >
          <FormControl sx={{ width: { xs: "100%", sm: "500px" } }}>
            <Typography>Search</Typography>
            <TextField
              placeholder="Search Users"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ marginRight: "10px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            gap={{ xs: 0, sm: 1 }}
            flexWrap={"wrap"}
            width={{ xs: "100%", sm: "auto" }}
          >
            <FormControl sx={{ width: { xs: "100%", sm: "250px" } }}>
              <Typography>Select Services</Typography>
              <Autocomplete
                value={agencyDropdown.find(
                  (data) => data.id === services
                ) || null}
                onChange={(event, newValue) => setServices(newValue?.id || "")}
                options={agencyDropdown}
                getOptionLabel={(option) => option.serviceName || ""}
                renderInput={(params) => (
                  <Input {...params} variant="outlined" />
                )}
              />
            </FormControl>
            <Stack mt={2} gap={1} display={"flex"} flexDirection={"row"}>
              <Button
                color="primary"
                // onClick={handleFilterClick}
                buttonName="Export"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                }}
              >
                <Box flexDirection={"column"} display={'flex'} gap={1} p={1}>
                  <Button
                    buttonName={"CSV"}
                    color="white"
                  />
                  <Button
                    buttonName={"Excel"}
                    color="white"
                  />
                </Box>
              </Popover>
              <Button
                buttonName={<VscFilter />}
                style={{
                  padding: "10px 11px",
                  backgroundColor: "secondary.main",
                }}
                color="white"
                size="small"
                variant="contained"
                onClick={handleFilterClick}
              />
            </Stack>
          </Stack>
        </Stack>

        <Collapse in={showFilter}>
          <Stack
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Input
                type="text"
                // labelinput={"ZipCode"}
                placeholder="User Name"
                formik={formik}
                name="name"
              />
            </Grid>

            {/* <Grid className="filterinput">
              <Input
                placeholder={"Search Position"}
                type="number"
                labelinput="Position"
              />
            </Grid> */}
            <Grid className="filterinput" style={{ marginTop: "-10px" }}>
              <Typography>{"Status"}</Typography>
              <CommonSelect
                name="status"
                formik={formik}
                value={formik.values.status}
                onChange={(e) => {
                  formik.setFieldValue("status", e.target.value);
                }}
                selectList={[
                  { name: "Select Status", id: "" },
                  { name: "Active", id: "1" },
                  { name: "Inactive", id: "0" },
                ]}
              />
            </Grid>
            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button color="primary" buttonName={"Filter"} onClick={formik.handleSubmit} />
              </Box>
              <Box>
                <Button
                  buttonName={"Clear"}
                  color="tertiary"
                  onClick={() => {
                    formik.resetForm();
                    formik.handleSubmit();
                    setShowFilter(false);
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Collapse>

        <TableContainer component={Paper}>
          <Table aria-label="waitlist table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">User/Family</TableCell>
                <TableCell align="center">Est. WaitingList Time </TableCell>
                <TableCell align="center">Position</TableCell>
                <TableCell align="center">Service</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {waitlistData && waitlistData.map((row) => (
                <TableRow key={row.id} hover onClick={() => navigate("/waitlist-details", { state: { id: row?.id } })}>
                  <TableCell align="center">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">
                    {row && row?.userId && row?.userId?.firstName + " " + row?.userId?.lastName}
                  </TableCell>
                  <TableCell align="center" >
                    {row.waitTime}
                  </TableCell>
                  <TableCell align="center">
                    {row.position}
                  </TableCell>
                  <TableCell align="center">
                    {row?.servicename}
                  </TableCell>
                  <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                    <Tooltip title={"Click to change status"}>
                      <Switch checked={row.status === 1 ? true : false} disabled={row?.isReffered === 1 ? true : false} />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="end" onClick={(e) => e.stopPropagation()}>
                    <Stack
                      flexDirection="row"
                      justifyContent="end"
                      alignItems="center"
                      gap={1}
                    >
                      <Select defaultValue="" displayEmpty>
                        <MenuItem value="">Select Action</MenuItem>
                        <MenuItem
                          onClick={() => navigate("/waitlist-details", { state: { id: row?.id } })}
                          value="view"
                        >
                          <MdVisibility style={{ marginRight: 8 }} />
                          View
                        </MenuItem>
                        {row?.isReffered === 0 && (
                          <MenuItem
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "addReffer",
                                para: row,
                              });
                            }}
                          >
                            <GrTransaction style={{ marginRight: 8 }} /> Refer
                          </MenuItem>
                        )}
                        {/* <MenuItem
                          onClick={() => handleAction(row.id, "update")}
                          value="update"
                        >
                          <RxUpdate style={{ marginRight: 8 }} />
                          Update
                        </MenuItem> */}
                        {/* <MenuItem
                          onClick={() => handleAction(row.id, "delete")}
                          value="delete"
                        >
                          <MdDelete style={{ marginRight: 8 }} />
                          Delete
                        </MenuItem> */}
                      </Select>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </>
  );
};

export default WaitingList;
