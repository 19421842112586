import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  FormControl,
  Stack,
  TableContainer,
  Paper,
  InputAdornment,
  Box,
  Avatar,
  Grid,
  IconButton,
  Tooltip,
  Switch,
  Chip,
} from "@mui/material";
import { Heading, Modal } from "../../../components/Common";
import { BiBarChart, BiUser } from "react-icons/bi";
import { BsActivity, BsEye } from "react-icons/bs";
import { MdSearch } from "react-icons/md";
import { ReferralDetailsModal } from "./Referral_Details";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { referralListAgncy, refferalApprove, refferalReject } from "../../../Service/Referral.service";
import moment from "moment";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
// Mock data for referrals
const referrals = [
  {
    id: 1,
    from: "Agency A",
    to: "Agency B",
    status: "Accepted",
    date: "2023-06-15",
  },
  {
    id: 2,
    from: "Agency B",
    to: "Agency C",
    status: "Rejected",
    date: "2023-06-18",
  },
  {
    id: 3,
    from: "Agency A",
    to: "Agency C",
    status: "Rejected",
    date: "2023-06-20",
  },
  {
    id: 4,
    from: "Agency C",
    to: "Agency B",
    status: "Accepted",
    date: "2023-06-14",
  },
  {
    id: 5,
    from: "Agency B",
    to: "Agency A",
    status: "Rejected",
    date: "2023-06-19",
  },
];


export default function Referral_Management() {
  const [filter, setFilter] = useState("");
  const [refferalsList, setRefferalsList] = useState([]);
  const [totalRefferals, setTotalRefferals] = useState(0);
  const [otherCounts, setOtherCounts] = useState(0);
  const dispatch = useDispatch();
  const handleGetRefferalData = async () => {
    try {
      let qry = `?search=${filter}`;
      const response = await referralListAgncy(qry);
      console.log(response);

      if (response.status) {
        setRefferalsList(response.data);
        setTotalRefferals(response.total);
        setOtherCounts(response.minPrice);
      } else {
        console.log(response.message);
        setRefferalsList([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetRefferalData();
  }, [filter]);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const naviget = useNavigate();

  const changeStatus = async () => {
    console.log("isModalOpen", isModalOpen);
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await refferalApprove(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
          ? await refferalReject(isModalOpen?.id)
          : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      handleGetRefferalData();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };

  return (
    <Stack gap={2}>
      <Heading head={"Referrals"} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            className="card_container border_card"
            p={"12px 16px"}
            // onClick={() => naviget("/total-referrals")}
            sx={{
              width: "100%",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "6px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer",
              ":hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography
                  color={"#888"}
                  textTransform={"capitalize"}
                  fontWeight="500"
                  fontSize="18px"
                >
                  Total Referrals
                </Typography>
                <span className="mainH1">{totalRefferals}</span>
              </Stack>
              <Box>
                <Avatar sx={{ bgcolor: "#4385F5" }}>
                  <BiBarChart />
                </Avatar>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            className="card_container border_card"
            p={"12px 16px"}
            // onClick={() => naviget("/active-referrals")}
            sx={{
              width: "100%",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "6px",
              cursor: "pointer",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              ":hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography
                  color={"#888"}
                  textTransform={"capitalize"}
                  fontWeight="500"
                  fontSize="18px"
                >
                  Active Referrals
                </Typography>
                <span className="mainH1">
                  {otherCounts}
                </span>
              </Stack>
              <Box>
                <Avatar sx={{ bgcolor: "#34A853" }}>
                  <BsActivity />
                </Avatar>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            className="card_container border_card"
            p={"12px 16px"}
            // onClick={() => naviget("/participating-agency")}
            sx={{
              width: "100%",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              borderRadius: "6px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              ":hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography
                  color={"#888"}
                  textTransform={"capitalize"}
                  fontWeight="500"
                  fontSize="18px"
                >
                  Participating Agencies
                </Typography>
                <span className="mainH1">{otherCounts}</span>
              </Stack>
              <Box>
                <Avatar sx={{ bgcolor: "#FABC05" }}>
                  <BiUser />
                </Avatar>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
          flexWrap: "wrap",
          width: "100%",
          gap: 1,
        }}
      >
        <FormControl sx={{ width: { xs: "100%", md: "500px" } }}>
          <Typography>Search </Typography>
          <TextField
            placeholder="Search"
            name="location"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </FormControl>

        {/* <FormControl sx={{ width: { xs: "100%", md: "250px" } }}>
          <Typography>Status</Typography>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
          </Select>
        </FormControl> */}
      </Box>

      <>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refferalsList && refferalsList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    No rows listed!
                  </TableCell>
                </TableRow>
              ) :
                refferalsList.map((referral) => (
                  <TableRow key={referral.id} onClick={() => {
                    setIsModalOpen({
                      open: true,
                      currentComponent: "viewreferral",
                      para: referral,
                    });
                  }}>
                    <TableCell>
                      {referral.id}
                    </TableCell>
                    <TableCell>
                      {referral?.referringAgencyName || ""}
                    </TableCell>
                    <TableCell>
                      {referral?.agencyName || ""}
                    </TableCell>
                    <TableCell>
                      {referral && referral.createdAt ? moment(referral?.createdAt).format("YYYY-MM-DD") : ""}
                    </TableCell>
                    <TableCell>
                      <Chip label={referral?.status === 0 ? "Pending" : referral.status === 1 ? "Accepted" : "Rejected"}
                        color={referral?.status === 0 ? "primary" : referral.status === 1 ? "success" : "error"}
                      />
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
                        {referral?.status === 0 ? (
                          <>
                            <Tooltip title="Approve">
                              <IconButton
                                sx={{
                                  backgroundColor: "secondary.main",
                                  color: "white.main",
                                }}
                                onClick={() => {
                                  setIsModalOpen({
                                    open: true,
                                    id: referral?.id,
                                    currentComponent: "status",
                                    head: "Accept Referral",
                                    para: "Are you sure you want to Accept this Referral?",
                                  });
                                }}
                              >
                                <TiTick size={15} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Reject">
                              <IconButton
                                sx={{
                                  backgroundColor: "red.main",
                                  color: "white.main",
                                }}
                                onClick={() => {
                                  setIsModalOpen({
                                    id: referral?.id,
                                    open: true,
                                    currentComponent: "delete",
                                    head: "Decline Referral",
                                    para: "Are you sure you want to Decline this Referral?",
                                  });
                                }}
                              >
                                <RxCross2 size={15} />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : ""}

                        <Tooltip title="View Referral">
                          <IconButton
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "viewreferral",
                                para: referral,
                              });
                            }}
                            sx={{
                              backgroundColor: "primary.main",
                              color: "white.main",
                            }}
                          >
                            <BsEye size={15} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={changeStatus}
      />
    </Stack>
  );
}
