import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Button, Input } from "../../../components/Common";
import { CloseIcon } from "../../../helper/Icons";

const Search_Agency_View = ({ handleClose, handelClick, modalOpen }) => {
  const { data } = modalOpen;
  console.log("modalOpen", data);
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Agency Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "600px" }}>
          {/* Agency Name */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Agency Name:</Typography>
            <Typography variant="body2">{data && data?.agency && data?.agency?.agencyName ? data?.agency?.agencyName : ""} </Typography>
          </Grid>

          {/* Agency Number */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Agency Number:</Typography>
            <Typography variant="body2">{data && data?.agency && data?.agency?.mobile ? data?.agency?.mobile : ""}</Typography>
          </Grid>

          {/* Agency Email */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Agency Email:</Typography>
            <Typography variant="body2">{data && data?.agency && data?.agency?.email ? data?.agency?.email : ""}</Typography>
          </Grid>

          {/* Agency Location */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Location:</Typography>
            <Typography variant="body2">
              {data && data?.agency && data?.agency?.address1 ? data?.agency?.address1 : ""}, {" "}
              {data && data?.agency && data?.agency?.address2 ? data?.agency?.address2 : ""}, {" "}
              {data && data?.city && data?.city?.cityName ? data?.city?.cityName : ""}, {" "}
              {data && data?.state && data?.state?.stateName ? data?.state?.stateName : ""} - {" "}
              {data && data?.zipcode ? data?.zipcode : ""}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1">Service:</Typography>
            <Typography variant="body2">
              {data && data?.agencyService && data?.agencyService.length > 0 ? data?.agencyService.map((service) => service?.serviceName).join(", ") : ""}

              {/* ABA Therapy, OT Services, Evaluations, Social Skills */}
            </Typography>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="tertiary"
          onClick={handleClose}
        />
        {/* <Button
          buttonName="Add"
          size="small"
          color="primary"
          onClick={handelClick}
        /> */}
      </DialogActions>
    </>
  );
};

export default Search_Agency_View;
