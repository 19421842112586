import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Badge,
  FormControl,
  Box,
  CardActions,
  Stack,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import { MdCalendarToday, MdSearch } from "react-icons/md";
import { BiMapPin } from "react-icons/bi";
import { Button, Heading, Input, Modal } from "../../../components/Common";
import { searchAgencyByuser } from "../../../Service/Agency.service";
import { getAllStates, getCity } from "../../../Service/location.service";
import { useFormik } from "formik";
import { serviceDropdown } from "../../../Service/ServiceDepartment.service";
import { Height } from "@mui/icons-material";

const serviceAvaiblity = [
  { id: 1, name: "Available" },
  { id: 2, name: "Limited" },
  { id: 3, name: "Unavailable" },
];

export default function Search_for_Services() {

  const [searchTerm, setSearchTerm] = useState("");
  const [agenciesData, setAgenciesData] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    data: {},
  });
  const [agencyDropdown, setAgencyDropdown] = useState([]);

  const formik = useFormik({
    initialValues: {
      state: "",
      city: "",
      zipcode: "",
      serviceType: "",
      availability: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      handleAgnciesData();
    },
  });


  const handleAgnciesData = async () => {
    try {
      const qry = `?search=${searchTerm}&state=${formik.values.state}&city=${formik.values.city}&zipcode=${formik.values.zipcode}&serviceType=${formik.values.serviceType}&availability=${formik.values.availability}`;
      const response = await searchAgencyByuser(qry);
      if (response.status) {
        setAgenciesData(response.data);
      } else {
        setAgenciesData([]);
      }
    }
    catch (error) {
      console.log("Error: ", error);
    }
  }

  const getAllStatesData = async () => {
    try {
      const res = await getAllStates();
      if (res.status) {
        setAllStates(res.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getCityByStateId = async (stateId) => {
    try {
      const res = await getCity(stateId);
      if (res.status) {
        setAllCities(res.data);
      } else {
        setAllCities([]);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getServiceDropdownData = async () => {
    try {
      const res = await serviceDropdown();
      if (res.status) {
        setAgencyDropdown(res.data);
      } else {
        setAgencyDropdown([]);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleAgnciesData();
  }, [searchTerm]);

  useEffect(() => {
    getAllStatesData();
    getServiceDropdownData();
  }, []);


  return (
    <>
      <Stack gap={2}>
        <Heading head={"Search for Services"} />
        <Box display="flex" gap="16px" flexWrap="wrap" flexDirection={"row"} alignItems={'end'} >
          <FormControl sx={{ width: { xs: "100%", md: "350px" } }}>
            <Typography>Search</Typography>
            <TextField
              placeholder="Search Service"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={1} alignContent={"end"}>
            {/* State Autocomplete */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>State</Typography>
              <Autocomplete
                value={
                  allStates.find(
                    (data) => data.id === formik.values.state
                  ) || null
                }
                name="state"
                onChange={(event, newValue) => {
                  formik.setFieldValue('state', newValue ? newValue.id : "");
                  getCityByStateId(newValue.id);
                  formik.setFieldValue('city', "");
                }}
                options={allStates}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            {/* City Autocomplete */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>City</Typography>
              <Autocomplete
                value={
                  allCities.find(
                    (data) => data.id === formik.values.city
                  ) || null
                }
                name="city"
                onChange={(event, newValue) => {
                  formik.setFieldValue('city', newValue ? newValue.id : "");
                }}
                options={allCities}
                getOptionLabel={(option) => option.cityName || ""}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            {/* ZipCode Autocomplete (unchanged) */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>ZipCode</Typography>
              {/* <TextField
                placeholder="Search Service"
                variant="outlined"
                formik={formik}
                name="zipcode"
              // value={formik.values.zipcode}
              /> */}
              <Input
                type="text"
                // labelinput={"ZipCode"}
                placeholder="ZipCode"
                formik={formik}
                name="zipcode"
              />
            </FormControl>
            {/* Service Type Autocomplete */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>Service Type</Typography>
              <Autocomplete
                value={
                  agencyDropdown.find(
                    (data) => data.id === formik.values.serviceType
                  ) || null
                }
                name="serviceType"
                onChange={(event, newValue) => {
                  formik.setFieldValue('serviceType', newValue ? newValue.id : "");
                }}
                options={agencyDropdown}
                getOptionLabel={(option) => option.serviceName || ""}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            {/* Availability Autocomplete */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>Availability</Typography>
              <Autocomplete
                value={
                  serviceAvaiblity.find(
                    (data) => data.id === formik.values.availability
                  ) || null
                }
                name="availability"
                onChange={(event, newValue) => {
                  formik.setFieldValue('availability', newValue ? newValue.id : "");
                }}
                options={serviceAvaiblity}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>

          </Box>
          <Box display={"flex"} gap={1} >

            <Button
              variant="contained"
              color="primary"
              buttonName={"Search"}
              onClick={formik.handleSubmit}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                formik.resetForm();
                formik.handleSubmit();
              }}
              buttonName={"Clear"}
            />
          </Box>
        </Box>

        <Stack>
          <Heading smallHead={"Available Location"} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gap: "16px",
            }}
          >
            {agenciesData && agenciesData.map((service) => (
              <Card key={service.id}>
                <CardHeader
                  title={service && service.agency && service.agency.agencyName ? service.agency.agencyName : "-"}
                  subheader={service?.serviceName || '-'}
                />
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <BiMapPin style={{ marginRight: "8px" }} />
                    <Typography>
                      {service && service.state && service.state.stateName ? service.state.stateName : "-"}, {" "}
                      {service && service.city && service.city.cityName ? service.city.cityName : "-"} - {" "}
                      {service && service.zipcode ? service.zipcode : "-"}
                    </Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MdCalendarToday style={{ marginRight: "8px" }} />
                    <Badge
                      sx={{
                        color:
                          service.availableSlots === 1
                            ? "#34A853"
                            : service.availableSlots === 2
                              ? "#FABC05"
                              : "#EA4235",
                        fontWeight: 700,
                      }}
                    >
                      {service.availableSlots === 1 ? "Available" : service.availableSlots === 2 ? "Limited" : "Unavailable"}
                    </Badge>
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    buttonName={"View Details"}
                    onClick={() => {
                      setIsModalOpen({
                        open: true,
                        currentComponent: "agencydetails",
                        data: service
                      });
                    }}
                  />
                </CardActions>
              </Card>
            ))}
          </div>
        </Stack>

        {agenciesData.length === 0 && (
          <Typography
            align="center"
            color="textSecondary"
            style={{ marginTop: "16px" }}
          >
            No services found matching your criteria.
          </Typography>
        )}
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
}
