import React, { useEffect } from "react";
import {
  Box,
  Paper,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { MdCheck, MdShoppingCartCheckout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Heading, Button } from "../../components/Common";
import { useLocation } from 'react-router-dom';
import { planDetail } from "../../Service/Plan.service";
import { useSelector } from "react-redux";
import { addToCart } from "../../Service/Cart.service";


const plans = [
  {
    title: "Monthly Plan",
    price: "$10",
    period: "per month",
    description: "Great value for medium-term goals",
    features: [
      "29 Days",
      "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    ],
  },
];

const Add_To_Cart = () => {
  const navigate = useNavigate();
  const [planData, setPlanData] = React.useState({
    plan: {},
    description: []
  });

  const { user } = useSelector((state) => state?.root?.auth);

  const location = useLocation();

  // Function to get query parameters
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  // Extracting the 'plan' variable
  const queryParams = getQueryParams(location.search);
  const plan = queryParams.get('plan');

  const checkLogin = async () => {
    const body = {
      cartId: "",
      planId: plan,
    };
    const res = await addToCart(body);
    if (res?.status) {
      console.log("Added to cart successfully", res);
    }

    if (user && user?.role) {
      navigate("/check-out", { state: { cartId: res?.data?.id } });
    } else {
      navigate("/", { state: { from: 1, cartId: res?.data?.id } });
    }
  };

  useEffect(() => {
    if (plan != "") {
      getPlanDetails(plan);
    }
  }, [plan]);

  const getPlanDetails = async (plan) => {
    // Fetch the plan details based on the plan variable
    const res = await planDetail(plan);
    console.log("planDatares", res?.status);

    if (res?.status) {
      setPlanData({
        plan: res.data,
        description: res.planSpecification
      })
    } else {
      console.log("Error in fetching plan details");
    }
  }

  console.log("planData", planData);

  return (
    <Stack p={4} sx={{ backgroundColor: "#fff" }}>
      <Paper elevation={4} sx={{ padding: 4, borderRadius: 3 }}>
        <Stack gap={4}>
          <Box display="flex" gap={1} alignItems="center">
            <ArrowBackIcon
              onClick={() => navigate("/choose-plan")}
              sx={{
                cursor: "pointer",
                backgroundColor: "#4385F5",
                color: "#fff",
                borderRadius: 1,
                padding: "4px",
                fontSize: "25px",
              }}
            />
            <Heading head=" Plan Details" />
          </Box>

          <Grid container spacing={2}>
            {/* Plan Card Section */}
            <Grid item xs={12} sm={6} md={4} >
              {/* {plans.map((plan, idx) => ( */}
              <Card
                sx={{
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      variant="h6"
                      fontSize={{ xs: "15px", sm: "23px" }}
                      fontWeight={600}
                    >
                      {planData?.plan?.planName}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="body2" color="textSecondary">
                      {`${planData?.plan?.planExpirationDuration} ${planData?.plan?.planDurationType == "1" ? "Months" : "Years"} Subscription`}
                    </Typography>
                  }
                />
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="flex-end"
                    gap={1}
                  >
                    <Typography
                      variant="h3"
                      fontSize={{ xs: "20px", sm: "36px" }}
                      fontWeight={600}
                    >
                      ${planData?.plan?.planPrice}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      {planData?.plan?.planDurationType == "1" ? "per month" : "per annum"}
                    </Typography>
                  </Box>

                  <Box mt={2}>
                    {planData?.description?.map((feature, idx) => (
                      <Grid
                        container
                        key={idx}
                        spacing={1}
                        alignItems="center"
                        mb={1}
                      >
                        <Grid item>
                          <MdCheck
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              color: "#34A853",
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Typography
                            variant="body2"
                            fontSize={{ xs: "12px", sm: "14px" }}
                            color="text.primary"
                          >
                            {feature?.subValue}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </CardContent>
              </Card>
              {/* ))} */}
              <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                <Button buttonName="Remove" onClick={() => navigate("/choose-plan")} color="red" />
              </Box>
            </Grid>

            {/* Summary Section */}
            <Grid item xs={12} sm={6} md={8}>
              <Card
                sx={{
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                  border: "2px solid",
                  borderColor: "grey.300",
                  padding: 3,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Heading smallHead="Summary" />
                {plans.map((plan, idx) => (
                  <Box key={idx} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6" fontWeight={600}>
                        Selected Plan:
                      </Typography>
                      <Typography variant="body1" fontWeight={600}>
                        {planData?.plan?.planName}
                      </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6" fontWeight={600}>
                        Plan Duration:
                      </Typography>
                      <Typography variant="body1" fontWeight={600}>
                        {planData?.plan?.planExpirationDuration + planData?.plan?.bonusDays} {planData?.plan?.planDurationType == "1" ? "Month" : "Months"}
                      </Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6" fontWeight={600}>
                        Price:
                      </Typography>
                      <Typography variant="body1" fontWeight={600}>
                        ${planData?.plan?.planPrice} {planData?.plan?.planDurationType == "1" ? "per month" : "per annum"}
                      </Typography>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="h6" fontWeight={600}>
                        Total Cost:
                      </Typography>
                      <Typography variant="body1" fontWeight={600}>
                        ${planData?.plan?.perMonthPrice * planData?.plan?.planExpirationDuration}
                      </Typography>
                    </Box>
                  </Box>
                ))}
                <Box mt={2}>
                  <Button
                    startIcon={<MdShoppingCartCheckout />}
                    variant="contained"
                    buttonName="Proceed to Checkout"
                    onClick={checkLogin}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Add_To_Cart;
