import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "../../Common";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { planAddInitialValues } from "../../../helper/initialValues";
import { planAddValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import { deleteDescription, planAdd, planDetail, planUpdate } from "../../../Service/Plan.service";
import CommonSelect from "../Select";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import Notification from "../Notification/Notification";


const Add_plans = ({ handleClose, modalOpen, handelClick, handleChange, status, editId }) => {

  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });

  const dispatch = useDispatch();
  const planDescriptions = async (id, index) => {
    const res = await deleteDescription(id);
    if (res && res.status === true) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: 'Description Deleted Successfully'
      }));
      let temp = [...descriptionData];
      temp.splice(index, 1);
      setDescriptionData(temp);
    };
  };

  console.log("modalOpen", modalOpen);

  const formik = useFormik({
    initialValues: planAddInitialValues,
    onSubmit: async (values) => {
      const payload = {
        "planName": values.planName,
        "planPrice": values.planPrice,
        "perMonthPrice": values.perMonthPrice,
        "dayCount": values.expirationDuration * 30,
        "planExpirationDuration": values.expirationDuration,
        "bonusDays": values.bonusDays,
        "planDurationType": values.planDurationType,
        "planDescription": descriptionData,
      };
      const res = modalOpen.isEdit ? await planUpdate(modalOpen?.id, payload) : await planAdd(payload);
      if (res && res.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Plan Added Successfully'
        }));
        handleClose();
      } else {
        setShowNotification({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong',
        });
      }
    },
    validationSchema: planAddValidationSchema,
  });



  const [descriptionData, setDescriptionData] = useState([]);
  const [descriptionIndex, setDescriptionDataIndex] = useState("");

  const manageDescription = (e) => {
    const check = e.target.value.replace(/(\r \n|\n \r|\r|\n)/g, "");

    if (check !== "") {
      if (e.key === "Enter") {
        if (descriptionIndex === "") {
          e.target.value = e.target.value.replace(/(\r \n|\n \r|\r|\n)/g, "");
          setDescriptionData([
            ...descriptionData,
            {
              id: formik.values.planDescriptionId,
              planId: editId?.id ? editId?.id : "",
              subValue: e.target.value,
            },
          ]);
          e.target.value = "";
          formik.setFieldValue("planDescription", "");
          formik.setFieldValue("planDescriptionId", "");
          setDescriptionDataIndex("");
        } else {
          e.target.value = e.target.value.replace(/(\r \n|\n \r|\r|\n)/g, "");
          if (!descriptionData[descriptionIndex]) {
            descriptionData[descriptionIndex] = {
              subValue: ""
            };
          }

          descriptionData[descriptionIndex].subValue = e.target.value;

          setDescriptionDataIndex("");
          formik.setFieldValue("planDescription", "");
          formik.setFieldValue("planDescriptionId", "");
          // setDescriptionDataIndex("")
        }
      }
    } else {
      e.target.value = "";
      formik.setFieldValue("planDescription", "");
      formik.setFieldValue("planDescriptionId", "");
    }
  };
  const handelDescriptionEdit = async (index) => {
    setDescriptionDataIndex(index);
  };


  useEffect(() => {
    if (modalOpen.isEdit) {
      getPlanData(modalOpen.id);
    }
  }
    , []);

  const getPlanData = async (id) => {
    const res = await planDetail(id);
    if (res && res.status) {
      formik.setFieldValue("planName", res.data.planName);
      formik.setFieldValue("planPrice", res.data.planPrice);
      formik.setFieldValue("planDurationType", res.data.planDurationType);
      formik.setFieldValue("perMonthPrice", res.data.perMonthPrice);
      formik.setFieldValue("expirationDuration", res.data.planExpirationDuration);
      formik.setFieldValue("bonusDays", res.data.bonusDays);
      setDescriptionData(res.planSpecification
        ? res.planSpecification.map((item) => {
          return {
            id: item.id,
            planId: item.planId,
            subValue: item.subValue,
          };
        })
        : []);
    }
  }


  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? "Edit Plan" : "Add Plan"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={1} maxWidth={{ sm: "600px" }}>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Plan Name"} type="text" maxLength={50} formik={formik} name="planName" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Plan Price"} type="text" maxLength={50} formik={formik} name="planPrice" />
            {/* <Typography sx={{ color: "red.main", marginTop: "-7px" }}>* Enter plan price monthly basis</Typography> */}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Plan Price Monthly"} type="text" maxLength={50} formik={formik} name="perMonthPrice" />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Select Plan Duration Type"}
            </Typography>
            <CommonSelect
              name="planDurationType"
              formik={formik}
              value={formik.values.planDurationType}
              onChange={(e) => {
                formik.setFieldValue("planDurationType", e.target.value);
              }}
              selectList={[
                { name: "None", id: "" },
                { name: "Monthly", id: "1" },
                { name: "Annual", id: "2" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Expiration Duration In months"}
              type="text"
              maxLength={2}
              formik={formik}
              name="expirationDuration"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Bonus Months"}
              type="text"
              maxLength={2}
              formik={formik}
              name="bonusDays"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Plan Description"}
              type="text"
              multiline
              rows={3}
              placeholder={"Plan Description..."}
              onKeyUp={manageDescription}
              formik={formik}
              name="planDescription"
            // value={values.planDescription}

            />
            <Typography sx={{ color: "red.main", marginTop: "-7px" }}>
              * Hit Enter For Add Description
            </Typography>
            <div className="description-container">
              {descriptionData.map((item, index) => (
                <div className="description-box" key={item.id}>
                  <p className="fontLight description-text">{item.subValue}</p>
                  <div className="description-actions">
                    <EditIcon
                      style={{
                        fill: "black",
                        cursor: "pointer",
                        fontSize: "20px",
                        marginLeft: "auto",
                        transition: "transform 0.2s",
                      }}
                      onClick={() => {
                        handelDescriptionEdit(index);
                        formik.setFieldValue("planDescription", item.subValue);
                        formik.setFieldValue("planDescriptionId", item.id);
                      }}
                      onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                      onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    />
                    <DeleteIcon
                      style={{
                        fill: "red", // Change to red for visibility
                        cursor: "pointer",
                        fontSize: "20px",
                        marginLeft: "10px", // Adjust spacing
                        transition: "transform 0.2s",
                      }}
                      onClick={() => {

                        planDescriptions(item.id, index);

                      }}
                      onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                      onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    />
                  </div>
                </div>
              ))}
            </div>

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="tertiary"
          onClick={handleClose}
        />
        <Button
          buttonName={modalOpen.isEdit ? "Save" : "Add"}
          size="small"
          color="primary"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
      <Notification show={showNotification} setShow={setShowNotification} />
    </>
  );
};

export default Add_plans;
