import { deleteRequest, get, post, put } from "../web.request";


export const waitlistAdd = async (data) => {
    return await post(`/addwaitlist`, data);
}

export const waitlistList = async (qry) => {
    return await get(`/waitlistList${qry}`);
}

export const waitlistListAgency = async (qry) => {
    return await get(`/waitlistlistagency${qry}`);
}

export const waitlistDetail = async (id) => {
    return await get(`/waitlistDetail/${id}`);
}

export const updateWaitlist = async (id, data) => {
    return await put(`/waitlistUpdate/${id}`, data);
}

export const waitlistExport = async (qry) => {
    return await get(`/waitlistExport${qry}`);
}
