import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  Box,
  IconButton,
  Tooltip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  CardContent,
} from "@mui/material";
import { Button, Heading, Input } from "../../components/Common";
import { MdEdit, MdSearch } from "react-icons/md";
import { VscFilter } from "react-icons/vsc";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { IoMdEye } from "react-icons/io";
import { CloseIcon } from "../../helper/Icons";
import { waitlistList } from "../../Service/Waitlist.service";
import moment from "moment";
import WailistDetailsPopup from "./Waitlist_Details_Popup";
import { serviceDropdown } from "../../Service/ServiceDepartment.service";
import { getAgencyDropdown } from "../../Service/Agency.service";

const Waitlist_Status = () => {
  const [waitlistData, setWaitlistData] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("serviceType");
  const [search, setSearch] = useState("");
  const [detailId, setDetailId] = useState(null);
  const [serviceData, setServiceData] = useState([]);
  const [agnciesData, setAgenciesData] = useState([]);

  const handleSort = (column) => {
    const isAscending = sortColumn === column && sortDirection === "asc";
    const sortedData = [...waitlistData].sort((a, b) => {
      if (a[column] < b[column]) return isAscending ? 1 : -1;
      if (a[column] > b[column]) return isAscending ? -1 : 1;
      return 0;
    });
    setSortDirection(isAscending ? "desc" : "asc");
    setSortColumn(column);
    setWaitlistData(sortedData);
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    if (showFilter === false) {
      getServiceData();
      getAgenciesData();
      setShowFilter(!showFilter);
    } else {
      setShowFilter(!showFilter);
    }
  };

  const handleGetWaitlistList = async () => {
    try {
      let qry = `?search=${search}&serviceId=${formik.values.serviceId}&agencyId=${formik.values.agencyId}`;
      const response = await waitlistList(qry);
      if (response.status) {
        setWaitlistData(response.data);
      } else {
        setWaitlistData([]);
      }
    }
    catch (error) {
      console.error("Error fetching waitlist data: ", error);
    }
  };

  useEffect(() => {
    handleGetWaitlistList();
  }, [search]);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = (id) => {
    setDetailId(id);
    setOpen(true);
  };

  const getServiceData = async () => {
    const response = await serviceDropdown();
    if (response?.status) {
      setServiceData(response?.data);
    } else {
      setServiceData([]);
    }
  };

  const getAgenciesData = async () => {
    try {
      const response = await getAgencyDropdown();
      if (response.status) {
        setAgenciesData(response.data);
      } else {
        setAgenciesData([]);
      }
    }
    catch (error) {
      console.error("Error fetching agencies data: ", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      serviceId: "",
      position: "",
      agencyId: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
      handleGetWaitlistList();
    },
  });

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Stack spacing={2}>
        <Heading head={"Waitlist Status"} />
        <Box
          sx={{
            marginBottom: 4,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "end",
            gap: 2,
          }}
        >
          <FormControl sx={{ width: { xs: "100%", md: "500px" } }}>
            <Typography>Search</Typography>
            <TextField
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={search}
              onChange={(e) => { setSearch(e.target.value); }}
            />
          </FormControl>

          {/* Service Category and Availability Filters */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              justifyContent: { xs: "start", sm: "flex-end" },
            }}
          >
            <Button
              buttonName={"Create Service"}
              color="primary"
              variant="contained"
              sx={{ height: "fit-content" }}
              onClick={() => {
                navigate("/create-service");
              }}
            />
            <Button
              buttonName={<VscFilter />}
              color="white"
              variant="contained"
              sx={{ height: "fit-content" }}
              onClick={() => handleFilterClick()}
            />
          </Stack>
        </Box>
        <Collapse in={showFilter}>
          <Stack
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <FormControl fullWidth>
                <Typography variant="h6">Select Service</Typography>
                <Autocomplete
                  sx={{ width: "250px !important" }}
                  value={
                    serviceData.find(
                      (data) => data.id === formik.values.serviceId
                    ) || null
                  }
                  name="serviceId"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('serviceId', newValue ? newValue.id : "");
                  }}
                  options={serviceData}
                  getOptionLabel={(option) => option.serviceName || ""}
                  renderInput={(params) => (
                    <Input {...params} variant="outlined"
                      placeholder="Select Service"
                      error={formik.touched.serviceId && Boolean(formik.errors.serviceId)}
                      helperText={formik.touched.serviceId && formik.errors.serviceId}

                    />
                  )}
                />
              </FormControl>
            </Grid>

            {/* <Grid className="filterinput">
              <Input
                placeholder={"Search Position"}
                type="number"
                labelinput="Position"
              />
            </Grid> */}

            <Grid className="filterinput">
              <FormControl fullWidth>
                <Typography variant="h6">Select Agency</Typography>
                <Autocomplete
                  sx={{ width: "250px !important" }}
                  value={
                    agnciesData.find(
                      (data) => data.id === formik.values.agencyId
                    ) || null
                  }
                  name="agencyId"
                  onChange={(event, newValue) => {
                    formik.setFieldValue('agencyId', newValue ? newValue.id : "");
                  }}
                  options={agnciesData}
                  getOptionLabel={(option) => option.agencyName || ""}
                  renderInput={(params) => (
                    <Input {...params} variant="outlined"
                      placeholder="Select Service"
                      error={formik.touched.agencyId && Boolean(formik.errors.agencyId)}
                      helperText={formik.touched.agencyId && formik.errors.agencyId}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button color="primary" buttonName={"Filter"} onClick={formik.submitForm} />
              </Box>
              <Box>
                <Button
                  buttonName={"Clear"}
                  color="tertiary"
                  onClick={() => {
                    formik.resetForm();
                    formik.submitForm();
                    setShowFilter(false);
                  }
                  }
                />
              </Box>
            </Stack>
          </Stack>
        </Collapse>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort("serviceType")}>
                  Service Type
                  {/* {sortColumn === "serviceType" && sortDirection === "asc" ? (
                    <MdArrowDropUp />
                  ) : (
                    <MdArrowDropDown />
                  )} */}
                </TableCell>
                <TableCell onClick={() => handleSort("position")}>
                  Position
                  {/* {sortColumn === "position" && sortDirection === "asc" ? (
                    <MdArrowDropUp />
                  ) : (
                    <MdArrowDropDown />
                  )} */}
                </TableCell>
                <TableCell onClick={() => handleSort("agency")}>
                  Agency
                  {/* {sortColumn === "agency" && sortDirection === "asc" ? (
                    <MdArrowDropUp />
                  ) : (
                    <MdArrowDropDown />
                  )} */}
                </TableCell>
                <TableCell>Child</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Init Date</TableCell>

                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {waitlistData.length === 0 ? (
                // Display "No data available" message when data is empty
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                waitlistData.map((row) => (
                  <TableRow key={row.id} onClick={() => handleClickOpen(row.id)}>
                    <TableCell>{row?.servicename}</TableCell>
                    <TableCell>{row?.position}</TableCell>
                    <TableCell>{row && row?.agencyId ? row?.agencyId?.agencyName : ""}</TableCell>
                    <TableCell>{row && row?.childName ? row?.childName : ""}</TableCell>
                    <TableCell>
                      {row && row?.state ? row?.state?.stateName : ""},{" "}
                      {row && row?.city ? row?.city?.cityName : ""},{" "}
                      {row && row?.zipcode ? row?.zipcode : ""}
                    </TableCell>
                    <TableCell>
                      {row && row?.initialDate ? moment(row?.initialDate).format("YYYY-MM-DD") : ""}
                    </TableCell>
                    <TableCell>
                      <Box gap={1} display={"flex"} justifyContent={"flex-end"}>
                        <Tooltip title="Edit">
                          <IconButton
                            sx={{
                              backgroundColor: "tertiary.main",
                              color: "white.main",
                            }}
                            onClick={() => {
                              // navigate("/view-waitlist-status", { state: { id: row?.id } });
                              navigate("/create-service", { state: { id: row?.id } });
                            }}
                          >
                            <MdEdit size={15} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="View">
                          <IconButton
                            sx={{
                              backgroundColor: "primary.main",
                              color: "white.main",
                            }}
                            onClick={() => handleClickOpen(row.id)}
                          // onClick={() => {
                          //   navigate("/view-waitlist-status");
                          // }}
                          >
                            <IoMdEye size={15} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                )))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
          Waitlist Status
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <WailistDetailsPopup id={detailId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="tertiary" buttonName={"Close"} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Waitlist_Status;
