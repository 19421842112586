import { get, post, put, deleteRequest } from "../web.request";

export const referralLists = async (qry) => {
    return await get(`/referAgencyListUser${qry}`);
}

export const transfrerToOtherAgency = async (data) => {
    return await post(`/referAgency`, data);
}

export const referralListAgncy = async (qry) => {
    return await get(`/referAgencyList${qry}`);
}

export const refferalDetails = async (id) => {
    return await get(`/referAgencyDetail/${id}`);
}

export const refferalApprove = async (id) => {
    return await put(`/referAgencyApprove/${id}`);
}

export const refferalReject = async (id) => {
    return await put(`/referAgencyReject/${id}`);
}
