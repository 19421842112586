import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  Switch,
  TextField,
  Grid,
  Stack,
  Box,
} from "@mui/material";
import { MdDoorbell } from "react-icons/md";
import { Button, Heading } from "../../../components/Common";
import { FaBell } from "react-icons/fa";
import { listSelectedService, updateServiceStatus } from "../../../Service/Agency.service";
import Notification from "../../../components/Common/Notification/Notification"

export default function Service_Availability() {

  const [notificationMessage, setNotificationMessage] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });

  const updateAvailability = async (id, isAvailable) => {
    try {
      const response = await updateServiceStatus(id);
      console.log(response);
      if (response.status) {
        setShowNotification({
          show: true,
          type: 'success',
          message: 'Service availability updated successfully.',
        });
        handleGetServiceList();
      } else {
        setShowNotification({
          show: true,
          type: 'error',
          message: response.message,
        });
        console.log(response.message);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const updateSlots = (id, slots) => {

  };

  const handleGetServiceList = async () => {
    try {
      const response = await listSelectedService();
      if (response.status) {
        setServiceList(response.data);
      } else {
        console.log(response.message);
        setServiceList([]);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetServiceList();
  }, []);

  return (
    <>
      <Stack gap={2}>
        <Heading head={"Service Availability"} />
        <Grid container spacing={1}>
          {serviceList && serviceList.map((service) => (
            <Grid item xs={12} sm={6} md={4} key={service.id}>
              <Card>
                <CardHeader
                  sx={{ paddingBottom: "0px !important" }}
                  title={service.serviceName}
                // subheader={service.availableSlots === 1 ? "1 slot available" : `${service.availableSlots} slots available`}
                // subheaderTypographyProps={{
                //   sx: { color: service.availableSlots ? "#34A853" : "#EA4235" },
                // }}
                />

                <Stack sx={{ padding: "16px", display: "flex", gap: "15px" }}>
                  <Box>
                    <Typography
                      component="label"
                      htmlFor={`available-${service.id}`}
                    >
                      Availability
                    </Typography>
                    <Switch
                      id={`available-${service.id}`}
                      checked={service.status}
                      onChange={(e) =>
                        updateAvailability(service.id, e.target.checked)
                      }
                    />
                  </Box>
                  {/* <Box>
                  <TextField
                    id={`slots-${service.id}`}
                    label="Available Slots"
                    type="number"
                    value={service.availableSlots}
                    onChange={(e) =>
                      updateSlots(service.id, parseInt(e.target.value))
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                    variant="outlined"
                    fullWidth
                  />
                </Box> */}
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* <Card>
        <CardHeader
          title="Notify Families"
          subheader="Send a notification when services become available"
        />
        <CardContent>
          <TextField
            placeholder="Enter notification message here..."
            value={notificationMessage}
            onChange={(e) => setNotificationMessage(e.target.value)}
            multiline
            rows={2}
            fullWidth
            variant="outlined"
          />
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={sendNotification}
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<FaBell />}
            buttonName={"Send Notification"}
          />
        </CardActions>
      </Card> */}
      </Stack>

      <Notification show={showNotification} setShow={setShowNotification} />
    </>
  );
}
