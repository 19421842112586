import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { MdDelete, MdEdit, MdSearch } from "react-icons/md";
import { Button, Heading, Modal } from "../../../components/Common";
import { referralLists } from "../../../Service/Referral.service";

const data = [
  {
    position: 1,
    from: "Helth Care",
    to: "Pathway Counseling Services",
    status: "Pending",
  },
  {
    position: 2,
    from: "Wellness Center for Kids",
    to: "Chicago, IL",
    status: "Pending",
  },
  {
    position: 3,
    from: "Pathway Counseling Services",
    to: "Bright Future Therapy",
    status: "Accepted",
  },
];
const Referral_Update = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [referralList, setReferralList] = useState([]);


  const getReferralList = async () => {
    try {
      let qry = `?search=${searchTerm}`;
      const response = await referralLists(qry);
      if (response.status) {
        setReferralList(response.data);
      } else {
        console.log(response.message);
        setReferralList([]);
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getReferralList();
  }, [searchTerm]);
  return (
    <>
      <Stack gap={2}>
        <Heading head={"Referral Update"} />

        <Box width={'100%'}>
          <FormControl sx={{ width: { xs: "100%", sm: "500px" } }}>
            <Typography fontSize={"14px"}>Search</Typography>
            <TextField
              placeholder="Search "
              variant="outlined"
              style={{ marginRight: "10px" }}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Refer By</TableCell>
                <TableCell>Refer To</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referralList && referralList.length == 0
                ? (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: "center" }}>
                      No rows listed!
                    </TableCell>
                  </TableRow>
                )
                : referralList.map((row) => (
                  <TableRow key={row.position}>
                    <TableCell>{row.serviceName}</TableCell>
                    <TableCell>{row.referringAgencyName}</TableCell>
                    <TableCell>{row.agencyName}</TableCell>
                    <TableCell>
                      <Chip
                        label={row.status === 0 ? "Pending" : row.status === 1 ? "Accepted" : "Rejected"}
                        color={
                          row.status === 0 ? "primary" : row.status === 1 ? "success" : "error"
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default Referral_Update;
