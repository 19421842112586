import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import { BackButton, Button, Heading } from "../../components/Common";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { useLocation } from "react-router-dom";
import { waitlistDetail } from "../../Service/Waitlist.service";
import moment from "moment";
import { convertTo12HourFormat } from "../../helper/TimeConvter";

const Waitlist_Details = () => {
  const { state } = useLocation();
  const [waitlistData, setWaitlistData] = useState(null);

  const handleGetWaitlistData = async (id) => {
    try {
      const response = await waitlistDetail(id);
      if (response.status) {
        setWaitlistData(response.data);
      } else {
        setWaitlistData({});
        console.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (state && state.id) {
      handleGetWaitlistData(state.id);
    }
  }, []);

  return (
    <>
      <Box display={"flex"} gap={1} mb={2}>
        <BackButton />
        <Heading head={waitlistData?.childName || ""} />
      </Box>

      <Container>
        <Card elevation={4}>
          <CardHeader
            title={
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  paddingBottom: "10px",
                }}
                color="primary"
              >
                {waitlistData && waitlistData?.serviceId && waitlistData?.serviceId?.serviceName ? waitlistData?.serviceId?.serviceName : ""}
              </span>
            }
            subheader={
              <>
                <Typography variant="subtitle1" color="textSecondary">
                  <DateRangeIcon fontSize="small" /> Date Init:{" "}
                  {waitlistData && waitlistData?.initialDate ? moment(waitlistData?.initialDate).format("YYYY-MM-DD hh:mm A") : ""}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  <WatchLaterIcon fontSize="small" /> Estimated Wait Time:{" "}
                  {waitlistData && waitlistData?.waitTime ? waitlistData?.waitTime : "-"}
                </Typography>
              </>
            }
          />

          <CardContent>
            {/* Child Information */}
            <Box mb={3}>
              <span className="mainh1">Child's Details</span>
              <Divider />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <AccountCircleIcon fontSize="small" />{" "}
                    <strong>Name:</strong> {waitlistData?.childName || ""}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <DateRangeIcon fontSize="small" /> <strong>DOB:</strong>{" "}
                    {waitlistData?.childDOB ? moment(waitlistData?.childDOB).format("YYYY-MM-DD") : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {/* Child Information */}
            <Box mb={3}>
              <span className="mainh1">Parent's Details</span>
              <Divider />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Parent's Name:</strong>{" "}
                    {waitlistData?.parentName || ""}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <PhoneIcon fontSize="small" /> <strong>Phone:</strong>{" "}
                    {waitlistData?.parentPhone || ""}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <EmailIcon fontSize="small" /> <strong>Email:</strong>{" "}
                    {waitlistData?.parentEmail || ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Address Information */}
            <Box mb={3}>
              <span className="mainh1">Address</span>
              <Divider />
              <Typography variant="body1" mt={1}>
                <LocationOnIcon fontSize="small" /> {waitlistData?.address1 || ""}
                {waitlistData?.address2 ? `, ${waitlistData?.address2}` : ""}
              </Typography>
              <Typography variant="body1">
                {waitlistData && waitlistData?.city ? `${waitlistData?.city?.cityName}, ` : ""}
                {waitlistData && waitlistData?.state ? `${waitlistData?.state?.stateName}, ` : ""}
                {waitlistData && waitlistData?.zipcode ? `${waitlistData?.zipcode}` : ""}
              </Typography>
            </Box>

            {/* Insurance Information */}
            <Box mb={3}>
              <span className="mainh1">Insurance Information</span>
              <Divider />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Insurance Name:</strong>{" "}
                    {waitlistData?.insuranceName || ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Availability Information */}
            <Box mb={3}>
              <span className="mainh1">Availability</span>
              <Divider />
              <Grid container >
                {waitlistData && waitlistData.waitlistAvailability && waitlistData.waitlistAvailability.length > 0 ?
                  waitlistData.waitlistAvailability.map((item) => (
                    <Grid xs={6} sm={3}>
                      <Typography variant="body1" mt={1}>
                        <strong>Day:</strong> {item?.day || '-'}
                      </Typography>
                      <Typography variant="body1">
                        <strong>From:</strong> {convertTo12HourFormat(item?.fromTime) || "-"}
                      </Typography>
                      <Typography variant="body1">
                        <strong>To:</strong> {convertTo12HourFormat(item?.toTime) || "-"}
                      </Typography>
                    </Grid>
                  )) : "No Data Found"
                }


              </Grid>
            </Box>
            {/* <Grid item xs={12} mt={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button buttonName={"Edit"} color="secondary" />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Button buttonName={"Cancel"} color="tertiary" />
                  <Button buttonName={"Save"} color="primary" />
                </Box>
              </Box>
            </Grid> */}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Waitlist_Details;
