import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Add_user, ChangePassword, Delete, Logout } from './modals';
import CreateSlot_Modal from '../../pages/Slot Management/CreateSlot_Modal';
import { ReferralDetails } from '../../pages/Referral  Section/Referral Management/Referral_Details';
import Reffer_modal from './modals/Reffer_modal';
import Add_plans from './modals/Add_plans';
import Create_Agency from './modals/Create_Agency';
import Search_Agency_View from '../../pages/Service Section/Search for Services/Search_Agency_View';
import UserDetail from './modals/UserDetail';

export default function CommonModal({ handleClose, modalOpen, handelClick, formik, data, setRefresh, refresh }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
      className={modalOpen.class}
    >
      {modalOpen.currentComponent === 'changePassword1' && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} formik={formik} />
      )}
      {['delete', 'status'].includes(modalOpen.currentComponent) && (
        <Delete handleClose={handleClose} modalOpen={modalOpen} handelClick={handelClick} />
      )}
      {modalOpen.currentComponent === 'logout' && (
        <Logout handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'createslot' && (
        <CreateSlot_Modal handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'AddUser' && (
        <Add_user handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'viewreferral' && (
        <ReferralDetails handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addReffer' && (
        <Reffer_modal handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} setRefresh={setRefresh}
          refresh={refresh} />
      )}
      {modalOpen.currentComponent === 'addplans' && (
        <Add_plans handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addAgency' && (
        <Create_Agency handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} data={data} />
      )}
      {modalOpen.currentComponent === 'agencydetails' && (
        <Search_Agency_View handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'UserDetails' && (
        <UserDetail handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} data={data} />
      )}
    </Dialog>
  );
}
